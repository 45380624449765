/* GENERALS */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@mixin font-style($size, $weight, $color, $letter, $line) {
  font-family: var(--default-font);
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line;
  letter-spacing: $letter;
}

/* SPECIFIC */
@mixin back-btn {

  .btn-back {
    padding: inherit;
    margin-right: 10px;
    transform: rotate(180deg);
    height: calc(100% - 5px);
    color: var(--table-title-color);
    cursor: pointer;
    transition: var(--standard-transition);

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
        transform: rotate(180deg) scale(0.9);
      }

    }

  }

}

@mixin mat-tab-group {

  mat-tab-group::ng-deep {
    height: 100%;
    min-height: 0;

    mat-tab-header {

      .mat-tab-label-container {

        .mat-tab-list {

          .mat-tab-labels {

            .mat-tab-label {
              width: auto;
              min-width: unset;
              margin-right: 20px;
              padding: 0;
              opacity: 1;

              .mat-tab-label-content {
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                background: var(--white-color);
                font-size: 12px;
                color: var(--black-color);
                text-transform: uppercase;
                opacity: 1;

                & > span:first-child {
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;

                  .mat-badge-content {
                    position: static;
                    top: unset;
                    margin-left: 10px;
                    font-size: 9px;
                    background: var(--badge-bg-color);
                  }

                }

              }

              &.mat-tab-label-active {
                opacity: 1;

                .mat-tab-label-content {
                  color: var(--brand-color-01);
                  border-bottom: 2px solid var(--primary-color);
                }

              }

            }

          }

          mat-ink-bar,
          mat-ink-bar::ng-deep {
            background-color: var(--primary-color);
          }

        }

      }

    }

    .mat-tab-body-wrapper {
      height: 100%;

      .mat-tab-body-content {
        overflow-y: auto;
        overflow-x: hidden;
        @include scroll-bar;
      }

    }

  }

}

@mixin scroll-bar {

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--light-gray);
    box-shadow: none;
    border: 2px solid var(--white-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white-color);
    border-radius: 10px;
    background: var(--white-color);
  }

}

@mixin scroll-bar-dark {

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--gray-color-09);
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--transparent-color);
    border-radius: 10px;
    background: var(--transparent-color);
  }

}

@mixin message {

  .message-container {
    min-width: 414px;
    max-width: 666px;
    min-height: 200px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 20px 25px;

    .main-container {
      flex: 1;
      height: 0;
      position: relative;
      box-sizing: border-box;
      padding-top: 2.5px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;

      h4::ng-deep {

        .warning {
          @include font-style(inherit, var(--font-regular), var(--button-deny-color), 0, inherit);
        }

      }

      .subtitle {
        font-size: 15px;
        line-height: 30px;
        user-select: none;
        color: var(--red-color);
        text-transform: uppercase;
        margin: 0;
      }

      .code {
        padding: 10px;
        border-radius: 10px;
        background-color: var(--black-color);
        color: var(--white-color);
        flex: 1;
        overflow: scroll;

        &::ng-deep {

          table td {
            text-align: left;
          }

          pre {
            text-align: justify;

            ul {

              li {

                .hidden {
                  display: none;
                }

              }

            }

          }

        }

      }

      .statistics {
        display: flex;
        flex-direction: row;

        & > div {
          flex: 1;

          tr {
            height: 35px;

            .value {
              @include font-style(30px, inherit, var(--primary-color), inherit, inherit);
            }

            .label {
              @include font-style(14px, var(--font-regular), var(--black-color), inherit, inherit);
            }

          }

        }

      }

    }

  }

}

@mixin chips-container {
  left: 0;
  background: var(--form-background);
  border-radius: 10px;
  padding: 7px 40px 7px 7px;
  box-sizing: border-box;
  position: relative;
  min-height: 44px;
  height: auto;

  .mat-chip {
    @include mat-chip;
  }

  @include icon-column;

  .icon-column {
    bottom: 0;
  }

  &.paymentMethod,
  &.paymentMethodPickUpAtRestaurant,
  &.paymentMethodDeliveryToAddress,
  &.paymentMethodDeliveryEasyOrder {
    height: 165px;

    span {

      img.image {
        max-width: 60px;
        margin-right: 10px;
        max-height: 20px;
      }

    }

  }

  &.imageDetails {

    .mat-chip {
      align-items: baseline;
      background: var(--transparent-color);
      box-shadow: none;
    }

    span {
      flex-direction: column;

      &.image-container {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        background-size: contain;
        background-position: center center;
        align-items: flex-end;
        justify-content: flex-end;
        background-repeat: no-repeat;
        padding: 5px;

        app-icon {
          font-size: 12px;
        }

      }

    }

  }

}

@mixin chips-main-container {

  .modal-type-container {
    position: relative;

    .open-modal-button {
      @include open-modal-button;
    }

    label {
      text-transform: capitalize;
      @include font-style(12px, var(--font-regular), var(--form-label-color), inherit, inherit);

      .required {
        color: var(--red-color);
      }

    }

    .chips-container {
      margin-top: 5px;
      @include chips-container;
    }

  }

}

@mixin datalean-editor {

  datalean-editor::ng-deep ejs-richtexteditor,
  datalean-editor::ng-deep ejs-richtexteditor::ng-deep .e-toolbar-wrapper,
  datalean-editor::ng-deep ejs-richtexteditor::ng-deep .e-rte-content {
    background: var(--form-background);
    border: 0;
    font-size: 14px;
    line-height: 24px;
  }

  datalean-editor::ng-deep ejs-richtexteditor,
  datalean-editor::ng-deep ejs-richtexteditor::ng-deep .e-toolbar-wrapper,
  datalean-editor::ng-deep ejs-richtexteditor::ng-deep .e-rte-toolbar {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  datalean-editor::ng-deep ejs-richtexteditor::ng-deep .e-rte-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  datalean-editor::ng-deep ejs-richtexteditor {
    border-radius: 10px;
  }

}

@mixin datalean-select($background) {

  mat-form-field,
  mat-form-field::ng-deep {
    margin-top: 5px;

    &.mat-form-field-disabled {
      opacity: 0.5;

      ::ng-deep * {
        cursor: not-allowed;
      }

    }

    .mat-form-field-wrapper {
      background: $background;
      border-radius: 10px;
      transform: translateY(0%);
      padding-top: 0;

      &:first-child {
        padding: 0;
      }

      .mat-form-field-flex {
        background-color: var(--transparent-color) !important;
        padding: 10px 20px;
        line-height: 24px;
        font-size: 14px;
        height: 44px;

        .mat-form-field-infix {
          border: none;
          padding: 0;

          mat-select::ng-deep {

            .mat-select-trigger {

              .mat-select-value {

                .mat-select-placeholder,
                .mat-select-value-text {
                  color: var(--form-text) !important;
                }

              }

              .mat-select-arrow-wrapper {
                transform: none !important;
              }

            }

          }

        }

      }

      .mat-form-field-underline {
        display: none;
      }

    }

  }

}

@mixin spinner {

  .spinner-container {
    position: fixed;
    @include flex-center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    right: 0;
    top: 0;

    &::ng-deep {

      circle {
        stroke: var(--primary-color);
      }

    }

  }

}

@mixin datalean-editor-page {

  :host {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    @include spinner();

    .editor-header {
      border-bottom: 1px solid var(--gray-color-05);
      padding: 20px 0;
      //margin-right: 30px;
      display: flex;
      justify-content: flex-end;

      .title {
        @include font-style(20px, var(--font-medium), var(--primary-toggle-color), inherit, 28px);
        text-align: left;
        user-select: none;
        margin-right: auto;
      }

      .action-container {
        @include action-container;
      }

    }

    .editor-container {
      height: 100%;
      overflow-y: auto;
      @include scroll-bar;

      mat-tab-group {
        height: 100%;
        display: flex;
        flex-direction: row-reverse;

        &::ng-deep {

          .mat-tab-body-wrapper {
            width: 100%;

            .mat-tab-body-content {
              overflow-y: auto;
              overflow-x: hidden;
              @include scroll-bar;
            }

          }

          .mat-tab-header {
            width: 80px;
            border-left: 1px solid var(--gray-color-05);

            .mat-tab-labels {
              flex-direction: column;

              .mat-tab-label {
                min-width: 0;
                height: 60px;
                opacity: 1;

                app-icon {
                  color: var(--gray-color-05);
                }

                .mat-badge-content {
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  font-size: 10px;
                  top: -20px;
                  background-color: var(--primary-color);
                }

                .mat-badge.mobile {
                  width: 20px;
                  height: 20px;

                  .mat-badge-content {
                    top: -15px;
                    right: 0;
                  }

                }

                &.mat-tab-label-active {

                  app-icon {
                    color: var(--primary-color);
                  }

                }

              }

            }

          }

          mat-ink-bar {
            display: none;
          }

        }

      }

    }

  }

}

@mixin form-field-container {

  .form-field-container {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 200px);

    .checkbox-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 0 40px 30px;

      .checkbox-position {
        display: flex;
        justify-items: center;
        width: calc(100% / 3);
        height: 40px;
        align-items: center;

        @include datalean-checkbox;
      }

      .structure-field-checkbox {

        .additional-form-field {
          padding: 0;
          margin: 0;

          &::ng-deep {
            @include datalean-checkbox;
          }

        }

      }

    }

    @include form-field;

    .additional-form-field,
    container-field {
      padding: 0 0 20px 30px;
    }

  }

}

@mixin status {

  td > span.status {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 10px;
      position: relative;
    }

    &.SCHEDULED {

      &::before {
        background-color: var(--primary-color);
      }

    }

    &.ENABLED,
    &.DELIVERED,
    &.PAYED,
    &.APPROVED,
    &.PASSWORD_RESTORED,
    &.ACCEPTED,
    &.ACTIVE,
    &.SENT,
    &.PUBLISHED {

      &::before {
        background-color: var(--green-color);
      }

    }

    &.OPENED,
    &.REFUSED,
    &.EXPIRED,
    &.DISABLED,
    &.MODIFYING_MERCHANT,
    &.TO_PAY {

      &::before {
        background-color: var(--red-color);
      }

    }

    &.PROCESSING,
    &.REGISTERED {

      &::before {
        background-color: yellow;
      }

    }

    &.DRAFT {

      &::before {
        background-color: var(--orange-color);
      }

    }

    &.CREATED,
    &.APPROVING,
    &.ARCHIVED,
    &.ACCEPTING {

      &::before {
        background-color: var(--medium-gray);
      }

    }

    &.EXPIRED {

      &::before {
        background-color: var(--form-text);
      }

    }

  }

  td > span.boolean {
    display: flex;
    align-items: center;

    &::before {
      font-family: var(--datalean-icon-font);
      display: flex;
      align-items: center;
      font-size: 15px;
    }

    &.true {

      &::before {
        content: 'd';
        color: var(--button-blue-color);
      }

    }

    &.false {

      &::before {
        content: 'g';
        color: var(--red-color);
      }

    }

  }

}

@mixin remote-data-table {

  data-table-sm::ng-deep {

    .table {
      @include status;
    }

  }

}

@mixin app-icon-datalean {

  app-icon {
    padding: 5px;
    cursor: pointer;
    user-select: none;
    @include flex-center;
    font-size: 14px;
    transition: var(--standard-transition);
    min-width: 23px;

    &.confirm {
      color: var(--button-confirm-color);
    }

    &.cancel {
      color: var(--button-deny-color);
    }

    &.sauron {
      color: var(--button-confirm-color);
    }

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
        transform: scale(0.9);
      }

    }

  }

}

@mixin form-field {

  .form-field,
  &.form-field {
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 30px;
    @include datalean-label;

    &::ng-deep .base-values-container {
      padding-right: 0;
    }

    @include datalean-select(var(--form-background));

    input,
    textarea {
      background: var(--form-background);
      border: 0;
      border-radius: 10px;
      font-size: 14px;
      line-height: 24px;
      padding: 10px 20px;

      &.product-name-input,
      &.wishlist-name-input {
        height: 60px;
        box-sizing: border-box;
        @include font-style(36px, var(--font-extralight), inherit, inherit, 40px);
        margin-right: 10px;
      }

      &.disabled,
      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

    }

    &.boolean {
      width: 100% !important;
      padding: 0 !important;
    }

  }

}

@mixin datalean-selection-modal {

  .selection-container {
    @include selection-container;

    .main-container {
      display: flex;
      flex: 1;
      height: 0;
      position: relative;
      box-sizing: border-box;

      .chips-container,
      .table-container {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        position: absolute;
      }

      .table-container {
        right: 0;
        margin-left: 10px;

        table {
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          @include scroll-bar;
          margin-top: 10px;

          .mat-row {
            display: flex;
            height: unset;

            &:last-of-type {

              > .mat-cell {
                border: 0;
              }

            }

            .mat-cell {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 5px 10px 0;
              width: 100%;

              span {
                width: calc(100% - 23px);
                display: flex;
                align-items: center;
                padding-right: 5px;
              }

              app-icon {
                font-size: 12px;
                width: 23px;
                height: 23px;
                user-select: none;
                @include flex-center;
                cursor: pointer;
                color: var(--button-blue-color);
                transition: var(--standard-transition);

                @media (hover: hover) and (pointer: fine) {

                  &:hover {
                    opacity: 0.5;
                    transform: scale(0.9);
                  }

                }

              }

            }

          }

          .mat-header-row {
            height: unset;
            width: 100%;
            display: flex;

            .mat-header-cell {
              padding-bottom: 5px;
              padding-top: 5px;
              display: flex;
              width: 100%;
            }

          }

        }

      }

      .chips-container {
        left: 0;
        background: var(--form-background);
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
        height: calc(100% - 5px);
        top: 5px;

        .mat-chip {
          min-width: 30%;
          @include mat-chip;
        }

      }

      .spinner-container {
        position: absolute;
        @include flex-center;
        width: 100%;
        height: calc(100% + 5px);
        background: rgba(255, 255, 255, 0.7);
        z-index: 2;
      }

    }

  }

  mat-paginator {
    border-top: 1px solid var(--row-separator-color);
    padding-top: 5px;

    &::ng-deep {

      .mat-paginator-container {
        min-height: 24px;
      }

      mat-form-field {
        width: 35px;

        .mat-form-field-underline {
          display: none;
        }

      }

      .mat-paginator-page-size-label {
        color: var(--form-text);
        margin: 0 10px;
      }

      .mat-paginator-range-actions {

        .mat-paginator-range-label {
          color: var(--form-text);
          margin: 0 16px 0 10px;
        }

        .mat-focus-indicator {
          color: var(--button-blue-color);
          height: 30px;
          @include flex-center;

          span {
            line-height: initial;
          }

          &[disabled='true'] {
            cursor: not-allowed;
            opacity: 0.3;
          }

        }

      }

    }

  }

}

@mixin datalean-table-arrows {

  .mat-header-cell {
    border: 0;

    &[aria-sort],
    &:focus {

      .mat-sort-header-container {

        .mat-sort-header-content {
          color: var(--black-color);
        }

        .mat-sort-header-arrow {

          .mat-sort-header-indicator {

            &::before {
              color: var(--black-color);
            }

          }

        }

      }

    }

    &[aria-sort='ascending']::ng-deep {

      .mat-sort-header-arrow {

        .mat-sort-header-indicator {

          &::before {
            transform: rotate(-180deg);
            opacity: 1;
          }

        }

      }

    }

    &[aria-sort='descending']::ng-deep {

      .mat-sort-header-arrow {

        .mat-sort-header-indicator {

          &::before {
            transform: rotate(0);
            opacity: 1;
          }

        }

      }

    }

    &::ng-deep {

      .mat-sort-header-indicator {

        &::before {
          content: 'o';
          top: 0;
          font-family: var(--datalean-icon-font);
          color: var(--black-color);
          margin: 0 4px;
          transform: rotate(0deg);
          opacity: 0.6;
          transition: var(--standard-transition);
        }

      }

      .mat-sort-header-arrow[style] {
        transform: translateY(0px) !important;

        .mat-sort-header-stem {
          display: none;
        }

        .mat-sort-header-indicator {
          transform: translateY(0px) !important;
          height: 100%;
          opacity: 1;
          color: var(--black-color);
          font-weight: var(--font-bold);
          position: relative;

          .mat-sort-header-pointer-left,
          .mat-sort-header-pointer-right,
          .mat-sort-header-pointer-middle {
            display: none;
          }

        }

      }

      .mat-sort-header-container {
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .mat-sort-header-content {
          font-size: 11px;
          text-transform: uppercase;
          color: var(--gray-color-10);
        }

        .mat-sort-header-arrow {
          opacity: 1 !important;
          margin: 0 5px 0 0;
          @include flex-center;
          width: 8px;
          min-width: 8px;

          .mat-sort-header-indicator {
            width: 8px;

            &::before {
              font-size: 8px;
              margin: 0;
              color: var(--gray-color-10);
            }

          }

        }

        .mat-sort-header-stem {
          background: unset;
        }

        .mat-sort-header-button {
          text-transform: uppercase;
        }

      }

    }

  }

}

@mixin select-country {

  mat-select-country.expanded::ng-deep {
    width: 150px;
  }

  mat-select-country::ng-deep {
    width: 50px;

    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        padding: unset;
      }

      .mat-form-field-flex {
        margin: 0;
        width: 100%;
        box-shadow: unset;
        background: var(--form-background);
        border: 0;
        border-radius: 10px;
        font-size: 14px;
        margin-top: 5px;
        @include flex-center;
        line-height: 19px;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .mat-form-field-underline {
        display: none;
      }

    }

  }

}

@mixin rounded-border-button {

  button {
    width: 171px;
    height: 28px;
    background-color: var(--transparent-color);
    border: 2px solid var(--primary-color);
    border-radius: 14px;
    @include font-style(11px, var(--font-medium), var(--primary-color), 1px, 16px);
    text-align: center;
    text-transform: uppercase;
    margin-right: 30px;
    cursor: pointer;
  }

}

@mixin dashboard-widget {
  position: relative;
  background: var(--dashboard-widget-background);
  height: 100%;
  border-radius: 20px;
  @include flex-center;
  flex-direction: column;
  overflow: hidden;

  .image {
    width: 160px;
    height: 160px;
    @include flex-center;

    @media only screen and (max-width: 540px) {
      position: absolute;
      left: -65px;
      width: 145px;
      height: 145px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    app-icon {
      font-size: 50px;
      color: var(--primary-color);
    }

  }

  .info {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 540px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: unset;
      justify-content: center;
      padding: 0 90px;
    }

    .title {
      @include font-style(30px, var(--font-medium), var(--dashboard-widget-title-color), 1px, 36px);
      text-align: center;
      margin-bottom: 25px;

      @media only screen and (max-width: 540px) {
        margin-bottom: 10px;
        line-height: 100%;
        text-align: left;
      }

    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 25px;
      align-items: center;

      .column {
        @include font-style(14px, inherit, inherit, inherit, 17px);
        height: 16px;

        &.label {
          @include font-style(inherit, var(--font-regular), var(--primary-toggle-color), inherit, inherit);
          text-transform: capitalize;
        }

        &.value {
          @include font-style(inherit, var(--font-bold), var(--dashboard-widget-value-color), inherit, inherit);
        }

      }

      &:not(:first-of-type) {
        border-top: 1px solid var(--dashboard-widget-divider-color);
      }

    }

    a {
      height: 36px;
      width: 160px;
      border-radius: 25px;
      border: 2px solid var(--primary-color);
      @include flex-center;
      @include font-style(11px, var(--font-medium), var(--primary-color), 1px, 16px);
      text-transform: uppercase;
      margin: 20px auto auto auto;
      cursor: pointer;
      transition: var(--standard-transition);

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          opacity: 0.5;
          transform: scale(0.9);
        }

      }

      @media only screen and (max-width: 540px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

    }

  }

}

@mixin select {

  mat-label {
    @include font-style(12px, var(--font-regular), var(--select-label-color), inherit, inherit);
    text-align: right;
    user-select: none;
    padding-right: 20px;
    padding-left: 20px;

    &.section-title {
      color: var(--table-option-title-color);
      font-weight: var(--font-medium);
      padding-left: 0;
    }

  }

  .select-container {
    display: flex;
    align-items: center;

    mat-select {
      height: 30px;
      background-color: var(--white-color);
      padding: 5px 10px 5px 15px;
      border-radius: 15px;
      width: 175px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      user-select: none;
      @include font-style(12px, var(--font-medium), inherit, inherit, 100%);
      box-shadow: none;

      ::ng-deep {

        .mat-select-trigger {
          display: flex;
          align-items: center;
        }

        .mat-select-value {
          max-width: 175px;

          span {
            @include font-style(inherit, var(--font-regular), var(--select-label-color), inherit, 100%);
          }

        }

      }

    }

  }

}

@mixin datalean-form-field {

  .form-field {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(50% - 10px);

    &.checkbox-field {
      flex-direction: row;

      label {
        order: 2;
        padding-left: 10px;
      }

      input {
        order: 1;
      }

    }

    &.first-name,
    &.last-name {

      input {
        @include font-style(36px, var(--font-extralight), inherit, inherit, 40px);
        height: 57px;
        padding: 0 10px;
      }

    }

    &.field-error {

      input {
        border: 1px solid var(--red-color) !important;
        color: var(--red-color);
      }

      .field-error-message {
        background-color: var(--transparent-color);
        border-radius: 10px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding: 5px;
        @include font-style(12px, var(--font-regular), var(--red-color), inherit, inherit);
      }

    }

    @include datalean-label;
    @include datalean-input;

  }

}

@mixin time-range {

  .row {
    display: flex;
    flex-direction: row;

    .column {
      display: flex;
      flex-direction: column;
      width: calc((100% - 30px) / 3);
      justify-content: center;
      position: relative;

      &.block {
        width: 40%;
        padding: 0 20px 10px 0;
      }

      &.weekday {
        width: 40px;
        @include font-style(20px, var(--font-medium), inherit, inherit, inherit);
      }

      &.interval {
        width: 20%;
        @include datalean-select(var(--form-background));

        .form-field {
          width: 100%;
        }
      }

      @include datalean-checkbox;
      @include datalean-input;

      mat-checkbox::ng-deep,
      mat-slide-toggle::ng-deep {

        label {
          justify-content: center;
          align-items: center;

          .mat-checkbox-inner-container {
            margin: unset;
            margin-right: 10px;
          }

        }

      }

    }

  }

}

.disable-block {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 999;
  background: var(--white-color);
  opacity: 0.7;
  bottom: 0;
  left: 0;
}

@mixin datalean-input {

  input,
  textarea {
    background: var(--form-background);
    color: var(--form-text);
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 5px;
    line-height: 24px;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;

    &.product-name-input {
      height: 60px;
      box-sizing: border-box;
      @include font-style(36px, var(--font-extralight), inherit, inherit, 40px);
      margin-right: 10px;
    }

    &[name='address'] {
      height: 122px;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

  }

  @include datalean-label;

}

@mixin datalean-label {

  label {
    text-transform: capitalize;
    @include font-style(12px, var(--font-regular), var(--form-label-color), inherit, inherit);
    display: flex;
    flex-direction: row;
    width: 100%;
    user-select: none;

    &.main {
      text-align: left;
      white-space: nowrap;
      @include font-style(16px, var(--font-regular), var(--primary-color), inherit, 24px);
      margin-bottom: 10px;
    }

    &.container-name {

      .name {
        @include font-style(12px, var(--font-regular), var(--form-label-color), inherit, inherit);
        text-transform: capitalize;
        user-select: none;
        margin-right: 10px;
      }

    }

  }

}

@mixin header {

  .table-header-container {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 20px 0 10px 0;

    .table-title {
      display: flex;
      align-items: center;
      text-align: left;
      @include font-style(20px, var(--font-medium), var(--table-title-color), inherit, 28px);
      user-select: none;
      margin-right: auto;
    }

    .optional-controls-container {
      display: flex;

      .filters-label {
        display: flex;
        align-items: center;
        @include font-style(12px, var(--font-regular), var(--select-label-color), inherit, inherit);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        padding-right: 0;
      }

      search-field {
        min-width: 250px;
        width: calc(200px + 5vw);
        margin-right: 10px;
      }

      .header-button {
        margin: 0 10px;
        @include flex-center;
        border: 0;
        cursor: pointer;
        transition: var(--standard-transition);

        &:last-of-type {
          margin-right: 0;
        }

        &.button-inp {
          transform: rotate(180deg);

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              transform: scale(0.9) rotate(180deg);
            }

          }

        }

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            opacity: 0.5;
            transform: scale(0.9);
          }

        }

        &.table-exp-inp-button,
        &.table-exp-xml-button {
          background-color: var(--button-blue-color);
          color: var(--white-color);
          width: 40px;
          height: 40px;
          box-shadow: none;
        }

        &.table-exp-xml-button {
          background-color: var(--important-button-color);
        }

        &.table-add-button {
          background-color: var(--important-button-color);
          color: var(--white-color);
          width: 40px;
          height: 40px;
          box-shadow: none;

          //&:active {
          //  box-shadow:  0px 3px 8px 3px rgba(0, 0, 0, 0.12)
          //}
        }

        &.table-settings-button {
          height: 100%;
          width: 40px;
          font-size: 20px;
          color: var(--button-blue-color);
        }

        &.table-grid-view-button {
          height: 100%;
          width: 40px;
          font-size: 17px;
          color: var(--button-blue-color);
          margin: 0 0 0 10px;
          opacity: 0.5;

          &.active {
            color: var(--standard-button-color-active);
            pointer-events: none;
            opacity: 1;
          }

        }

        &.table-list-view-button {
          height: 100%;
          width: 40px;
          font-size: 20px;
          color: var(--button-blue-color);
          margin: 0 10px 0 0;
          opacity: 0.5;

          &.active {
            color: var(--standard-button-color-active);
            pointer-events: none;
            opacity: 1;
          }

        }

      }

    }

  }

}

@mixin datalean-date-picker-container {
  display: flex;
  justify-content: space-between;

  .checkbox-date-container {
    width: 50%;
    display: flex;
    align-items: center;
    @include form-field-radio;
  }

  @include datalean-date-picker;
}

@mixin date-picker {
  justify-content: space-between;
  @include time-selection;

  mat-form-field.date-picker::ng-deep {

    .mat-datepicker-toggle-active {

      svg {
        color: var(--color-brown);
      }

    }

    @include mat-form-field-flex;

  }

  .date-selection-container {
    margin-right: 2%;
  }

  .time-selection-container,
  .date-selection-container {
    width: 49%;
    padding-bottom: 0;

    label {
      padding-bottom: 5px;
      user-select: none;
      text-transform: capitalize;
      @include font-style(12px, var(--font-regular), var(--form-label-color), inherit, 16px);
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0;
    }

    mat-select,
    mat-form-field,
    mat-form-field::ng-deep .mat-form-field-flex {
      width: 100% !important;
      max-width: unset;
      margin: 0 !important;
    }

    mat-select {
      height: 44px !important;
    }

    mat-form-field::ng-deep .mat-form-field-flex {
      height: 44px;
    }

  }

  .time-selection-container {
    padding-bottom: 16px;
  }

}

@mixin mat-form-field-flex {

  .mat-form-field-flex {
    background-color: var(--form-background);
    padding: 0 15px;
    border-radius: 10px;
    max-width: 150px;
    width: 150px;
    display: flex;
    height: 44px;
    align-items: center;
    user-select: none;
    font-weight: var(--font-bold);
    font-size: 14px;
    box-shadow: 0 0 15px var(--color-mediumgray);
  }

}

@mixin time-selection {

  .time-selection-container {
    padding-bottom: 20px;

    label {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-left: 50px;
      @include font-style(10px, var(--font-bold), var(--color-orange), 1px, inherit);
    }

    .time-selection {
      margin-top: 2px;

      .mat-select {

        &:last-of-type {
          margin-left: 10px;
        }

      }

      mat-select {
        width: 120px;
        background-color: var(--form-background);
        border-radius: 10px;
        height: 25px;
        user-select: none;
        @include font-style(12px, var(--font-medium), inherit, inherit, inherit);
        padding: 7px;

        ::ng-deep {

          .mat-select-trigger {
            display: flex;
            align-items: center;
            height: 100%;
          }

          .mat-select-value {
            max-width: 95%;

            span {
              @include font-style(13px, var(--font-regular), inherit, inherit, inherit);
            }

          }

        }

      }

    }

  }

}

@mixin datalean-checkbox {

  mat-checkbox {
    margin-top: 5px;
    @include form-field-checkbox;
  }

  mat-slide-toggle {
    margin-top: 5px;
    @include form-field-toggle;
  }

}

@mixin datalean-date-picker {

  .date-picker-container {
    width: 50%;
    display: flex;
    align-items: center;

    .mat-form-field {
      padding-right: 50px;
      width: 100%;

      &::ng-deep {

        .mat-form-field-wrapper {
          padding: 0;

          .mat-form-field-flex {
            height: 44px;
            background: var(--form-background);
            border-radius: 10px;
            @include flex-center;
            padding: 10px;

            .mat-form-field-infix {
              padding: 0;
              margin: 0;
              border: 0;

              .mat-input-element {
                caret-color: var(--black);
                color: var(--form-text);
                font-size: 14px;
                line-height: 24px;
              }

              mat-select::ng-deep {

                .mat-select-trigger {

                  .mat-select-value {}

                  .mat-select-arrow-wrapper {
                    transform: unset;
                  }

                }

              }

            }

          }

        }

        .mat-form-field-underline {
          display: none;
        }

      }

      &.mat-focused {

        &::ng-deep {

          .mat-form-field-underline {

            span {
              background-color: var(--button-blue-color) !important;
            }

          }

        }

      }

    }

  }

}

@mixin icon-column {

  .icon-column,
  .command-list-container {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    z-index: 30;
    position: absolute;
    right: 0;

    .button,
    app-icon::ng-deep .button {
      background-color: var(--transparent-color);
      border-radius: 100%;
      width: 23px;
      height: 23px;
      user-select: none;
      cursor: pointer;
      box-sizing: border-box;
      z-index: 999;
      padding-top: 2px;
      padding-left: 0.5px;

      &.open-modal,
      &.mode-up,
      &.mode-down,
      &.add,
      &.info {
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
      }

      &.mode-up {
        transform: rotate(-90deg);
      }

      &.mode-down {
        transform: rotate(90deg);
      }

      &.mode-up,
      &.mode-down,
      &.add,
      &.info {
        background-color: var(--primary-color);
        color: var(--white-color);
      }

      &.clear,
      &.delete {
        color: var(--red-color);
        border: 1px solid var(--red-color);
      }

      &.disabled {
        color: var(--white-color);
        background: var(--medium-dark-grey);
      }

    }

    app-icon::ng-deep {

      &.mat-tooltip-trigger::ng-deep {
        @include form-field-tooltip;
      }

    }

  }

}

@mixin paginator {

  mat-paginator {
    border-top: 1px solid var(--row-separator-color);

    &::ng-deep {

      button {
        transition: var(--standard-transition);

        &.mat-button-disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }

      }

      mat-form-field {
        width: 35px;

        .mat-form-field-flex {
          border-radius: 0;
          padding: 0;
          background-color: unset;

          .mat-form-field-infix {
            padding: 0.5em 0;

            .mat-select-arrow-wrapper {
              transform: none;
            }

          }

        }

        .mat-form-field-underline {
          display: none;
        }

      }

      .mat-paginator-page-size-label {
        color: var(--form-text);
        margin: 0 10px;
      }

      .mat-select-trigger,
      .mat-select-value-text,
      .mat-select-arrow {
        color: var(--form-text);
      }

      .mat-paginator-range-actions {
        &.hover {
          display: none;
        }

        .mat-paginator-range-label {
          color: var(--form-text);
          margin: 0 16px 0 10px;
        }

        .mat-focus-indicator {
          color: var(--button-blue-color);
          width: 50px;

          &[disabled='true'] {
            cursor: not-allowed;
            opacity: 0.3;
          }

        }

      }

    }

  }

}

/* NUOVI DAL 2023 */
@mixin header-select-container {
  margin-left: 10px;
  margin-right: 10px;

  .language,
  .community,
  .status {
    display: flex;
    height: 100%;
    align-items: center;

    label {
      padding-right: 10px;
      user-select: none;
      @include font-style(12px, var(--font-medium), var(--select-label-color), inherit, inherit);
    }

    .mat-select {
      background: var(--form-background);
      padding: 5px 10px 5px 15px;
      border-radius: 15px;
      width: 175px;
      user-select: none;
      @include font-style(12px, var(--font-medium), inherit, inherit, inherit);
      box-shadow: none;

      &::ng-deep {

        .mat-select-trigger {
          display: flex;
          align-items: center;
        }

        .mat-select-arrow {
          color: var(--select-label-color);
        }

        .mat-select-value {
          max-width: 175px;

          span {
            @include font-style(inherit, var(--font-regular), var(--select-label-color), inherit, inherit);
          }

        }

      }

    }

  }

}

@mixin action-container {
  //v x nelle opzioni di visualizzazione
  display: flex;
  justify-content: space-around;

  app-icon {
    padding: 10px;
    cursor: pointer;
    user-select: none;
    min-width: 23px;
    transition: var(--standard-transition);

    &:last-of-type {
      padding-right: 0;
    }

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
        transform: scale(0.9);
      }

    }

    &.confirm {
      @include flex-center;
      color: var(--button-confirm-color);
    }

    &.cancel {
      @include flex-center;
      color: var(--button-deny-color);
    }

  }

}

@mixin open-modal-button {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 100%;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 23px;
  height: 23px;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: var(--standard-transition);

  @media (hover: hover) and (pointer: fine) {

    &:hover {
      opacity: 0.5;
      transform: scale(0.9);
    }

  }

}

@mixin mat-chip {
  background: var(--chip-background-color);
  padding: 10px;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  user-select: none;
  height: unset;
  color: var();
  min-height: 30px;
  @include font-style(12px, var(--font-regular), var(--black-color), inherit, inherit);
  cursor: pointer;

  app-icon {
    color: var(--red-color);
    @include flex-center;
    opacity: 1;
    font-size: 8px;
    cursor: pointer;
    transition: var(--standard-transition);

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
        transform: scale(0.9);
      }

    }

  }

  span {
    display: flex;
    align-items: center;
  }

}

@mixin selection-container {
  width: 50vw;
  height: 50vh;
  min-width: 600px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 25px 25px 20px 25px;

  .subtitle {
    @include font-style(12px, var(--font-regular), var(--gray-color-10), 0, 16px);
    text-align: left;
    user-select: none;
  }

}

@mixin form-field-input {
  padding: 10px 15px;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background: var(--gray-color-12);
  @include font-style(14px, var(--font-regular), var(--brand-color-09), inherit, 150%);
  cursor: inherit;
  position: relative;
}

@mixin form-field-input-number {
  /* da mettere nel .input-container.number e poi dentro input aggiungere @include form-field-input; quando il campo è di tipo numero */
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: 20px;

    &::-webkit-inner-spin-button {
      opacity: 0;
      cursor: pointer;
    }

  }

  .number-arrows {
    width: 15px;
    height: 20px;
    position: absolute;
    right: 20px;
    @include flex-center;
    flex-flow: column;
    pointer-events: none;
    cursor: pointer;

    &::before {
      content: 'b';
      width: 15px;
      height: 10px;
      font-family: var(--datalean-icon-font);
      font-size: 8px;
      color: var(--gray-color-11);
      transform: rotate(-90deg);
      background: var(--form-background);
      @include flex-center;
      pointer-events: none;
      cursor: pointer;
    }

    &::after {
      content: 'b';
      width: 15px;
      height: 10px;
      font-family: var(--datalean-icon-font);
      font-size: 8px;
      color: var(--gray-color-11);
      transform: rotate(-90deg) scaleX(-1);
      background: var(--form-background);
      @include flex-center;
      pointer-events: none;
      cursor: pointer;
    }

  }

}

@mixin form-field-input-date {
  /* in aggiunta a @include form-field-input; quando il campo è di tipo data */
  width: -webkit-fill-available;

  &::-webkit-calendar-picker-indicator {
    width: 18px;
    height: 100%;
    position: absolute;
    right: 10px;
    padding: 0;
    background: var(--transparent);
    cursor: pointer;
  }

  &::before {
    content: '';
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    background: var(--gray-color-12);
    pointer-events: none;
    cursor: pointer;
  }

  &::after {
    content: '|';
    font-family: var(--datalean-icon-font);
    font-size: 18px;
    color: var(--brand-color-10);
    position: absolute;
    right: 10px;
    pointer-events: none;
    cursor: pointer;
  }

}

@mixin form-field-toggle {
  //da mettere dentro mat-slide-toggle {}
  height: 40px;

  &.mat-checked::ng-deep {

    .mat-slide-toggle-bar {
      background: var(--brand-color-10);

      input {
        // linea
      }

      .mat-slide-toggle-thumb-container {
        transform: translate3d(34px, 0, 0);

        .mat-slide-toggle-thumb {
          //cerchio
          background: var(--white-color);

          &::before {
            content: '7';
            color: var(--brand-color-10);
          }

        }

      }

    }

  }

  &::ng-deep {

    .mat-slide-toggle-bar {
      width: 70px;
      height: 36px;
      border-radius: 18px;
      background: var(--gray-color-03);
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .mat-slide-toggle-thumb-container {
        width: 26px;
        height: 26px;
        top: unset;
        left: 5px;

        .mat-slide-toggle-thumb {
          width: 26px;
          height: 26px;
          border-radius: 100%;
          background: var(--brand-color-10);
          @include flex-center;

          &::before {
            content: 'g';
            font-family: var(--datalean-icon-font);
            font-size: 8px;
            color: var(--white-color);
            cursor: pointer;
            text-transform: none;
          }

        }

        .mat-slide-toggle-ripple {
          display: none;
        }

      }

    }

    .mat-slide-toggle-content {
      @include font-style(14px, var(--font-regular), var(--brand-color-09), inherit, 150%);
    }

  }

}

@mixin form-field-radio {
  //da mettere dentro a mat-radio-group

  mat-radio-button {
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    //min-width: 200px;

    &::ng-deep {

      .mat-radio-label {
        color: var(--form-label-color);

        .mat-radio-container {
          width: 15px;
          height: 15px;
          @include flex-center;

          .mat-radio-outer-circle {
            width: 14px;
            height: 14px;
            background-color: var(--gray-color-05);
            border: none;
          }

          .mat-radio-inner-circle {
            width: 14px;
            height: 14px;
            background-color: var(--brand-color-01);
          }

          input.mat-radio-input {
            display: none;
          }

          .mat-radio-ripple {
            display: none;
          }

        }

        .mat-radio-label-content {
          @include font-style(14px, var(--font-regular), var(--gray-color-11), inherit, 150%);
        }

      }

    }

  }

}

@mixin form-field-select {
  //da mettere dentro a mat-select
  height: 40px;
  box-sizing: border-box;
  min-width: 175px;
  max-width: 175px;
  background-color: var(--gray-color-12);
  padding: 5px 10px 5px 15px;
  border-radius: 10px;
  border: none;
  user-select: none;

  .mat-select-trigger {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .mat-select-value {
      width: 100%;
      height: 100%;
      max-width: 175px;

      .mat-select-value-text {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .mat-select-min-line {
          @include font-style(inherit, var(--font-regular), var(--select-label-color), inherit, inherit);
        }

      }

    }

    .mat-select-arrow-wrapper {

      .mat-select-arrow {
        color: var(--gray-color-11);
      }

    }

  }

}

@mixin form-field-checkbox {
  //da mettere dentro a mat-checkbox
  margin-right: 20px;

  &.mat-checkbox-indeterminate::ng-deep {

    .mat-checkbox-background {
      background: var(--brand-color-10);

      &::before {
        content: '';
        width: 7px;
        height: 1px;
        background: var(--white-color);
        position: absolute;
      }

    }

  }

  &.mat-checkbox-checked::ng-deep {

    .mat-checkbox-layout {

      .mat-checkbox-inner-container {

        .mat-checkbox-background {
          background: var(--brand-color-10) !important;
        }

      }

    }

  }

  &::ng-deep {
    //da mettere dentro a mat-checkbox

    .mat-checkbox-layout {
      display: flex;
      align-items: center;

      .mat-checkbox-inner-container {
        width: 13px;
        height: 13px;
        border-radius: 3px;
        border: 1px solid var(--gray-color-13);
        background: var(--white-color);
        margin: 0 10px 0 0;
        display: flex;

        .mat-checkbox-input {
        }

        .mat-checkbox-ripple {
          display: none;
        }

        .mat-checkbox-frame {
          display: none;
        }

        .mat-checkbox-background {
          border-radius: 0;

          .mat-checkbox-checkmark {
          }

          .mat-checkbox-mixedmark {
            display: none;
          }

        }

      }

      .mat-checkbox-label {
        //es. testo lunedì
        width: auto;
        max-width: 200px;
        @include font-style(14px, var(--font-regular), var(--brand-color-09), 0, 150%);
      }

    }

  }

}

@mixin form-field-tooltip {
  width: 22px;
  height: 22px;
  background: var(--gray-color-13);
  border-radius: 3px;
  @include flex-center;
  transition: var(--standard-transition);
  margin-right: 20px;

  @media (hover: hover) and (pointer: fine) {

    &:hover {
      opacity: 0.5;
    }

  }

  ::ng-deep {

    .icon {
      font-size: 12px;
      color: var(--white-color);
      cursor: pointer;
      @include flex-center;
    }

  }

}

@mixin form-section-header {
  width: 100%;

  .section-title {
    //Es. titolo "Impostazioni prenotazione"
    width: 100%;
    @include font-style(16px, var(--font-regular), var(--brand-color-01), 0, 150%);
    margin-bottom: 10px;
  }

  .section-text {
    width: 100%;
    @include font-style(14px, var(--font-regular), var(--brand-color-09), 0, 150%);
  }

}

@mixin form-section {
  display: flex;
  flex-flow: column;
  margin-top: 40px;

  .section-header {
    @include form-section-header;
  }

  /* CAMPI COLLAPSIBLE */
  .container-item {
    display: flex;
    flex-flow: row wrap;
    background: var(--transparent-color);
    box-sizing: border-box;
    margin-bottom: 10px;

    .container-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: var(--form-background);
      border-radius: 10px;
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;

      .expandable-button {
        height: 100%;
        color: var(--primary-color);
        font-size: 8px;
        padding-top: 0;
        transform: rotate(90deg);
        cursor: pointer;
        @include flex-center;
        transition: var(--standard-transition);

        &.collapsed {
          transform: rotate(0);
        }

      }

      .container-name {
        @include font-style(12px, var(--font-semibold), var(--black-color), inherit, inherit);
        text-transform: uppercase;
        user-select: none;
        padding: 10px;
        width: 100%;
        display: flex;
      }

    }

    .collapsible {
      border: 1px solid var(--gray-color-03);
      border-top: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-top: -10px;
      margin-bottom: 10px;
      padding: 20px 15px;
      transition: var(--standard-transition);
      display: flex;
      flex-flow: column;
      width: 100%;
      box-sizing: border-box;

      &.collapsed {
        border: var(--transparent-color);
        padding: 0;
        height: 0;
        overflow: hidden;
      }

    }

  }

  /* CAMPI NORMALI */
  .row-fields-container {
    width: 100%;
    height: auto;
    display: flex;
    padding: 20px 0 0 0;

    > label {
      //Es. titolo "Durata della prenotazione"
      width: 12.5%;
      //max-width: 200px;
      height: 40px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      @include font-style(12px, var(--font-regular), var(--gray-color-11), inherit, 150%);
    }

    .fields-container {
      width: calc(87.5% - 20px);
      display: flex;
      flex-flow: row wrap;

      .form-field {
        float: left;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-right: 20px;

        &.ratio-auto {
          width: auto;
          max-width: unset;
        }

        &.ratio-100 {
          width: 100%;
          margin: 0;
          padding: 0 !important;
        }

        label {
          //Es. testo "Check-in"
          width: auto;
          height: 40px;
          margin-right: 20px;
          display: flex;
          align-items: center;
          @include font-style(12px, var(--font-regular), var(--gray-color-11), inherit, 150%);
        }

        /* TESTO E INPUT A LATO */
        .input-container {
          width: 100%;
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }

          input {
            width: 100%;
            @include form-field-input;
          }

          &.number {
            @include form-field-input-number;

            input {
              max-width: 90px;
            }

          }

          &.date {

            input {
              @include form-field-input-date;
            }

          }

        }

        /* TOGGLE BUTTON */
        .toggle-container {

          mat-slide-toggle {
            width: 100%;
            @include form-field-toggle;
          }

        }

        /* RADIO BUTTON */
        .radio-container {
          width: 100%;
          display: flex;
          align-items: center;

          //&.with-tooltip {
          //
          //  mat-radio-group {
          //
          //    mat-radio-button {
          //      margin-right: 0;
          //    }
          //
          //  }
          //
          //}

          mat-radio-group {
            height: 40px;
            display: flex;
            align-items: center;
            @include form-field-radio;

            mat-radio-button {
              margin-right: 20px;
              padding: 0;
            }

          }

        }

        /* CHECKBOX BUTTON */
        .checkbox-container {

          mat-checkbox {
            @include form-field-checkbox;
          }

        }

        /* SELECT */
        .select-container {

          &.bigger {

            mat-select::ng-deep {
              min-width: 400px;
              max-width: 400px;

              .mat-select-trigger {

                .mat-select-value {
                  max-width: 400px;
                }

              }

            }

          }

          mat-select::ng-deep {
            @include form-field-select;
          }

        }

        /* CHIPS */
        .chips-container {
          @include chips-container;
          width: 100%;
          margin: 0;
          padding: 20px;
          position: relative;
          min-height: 150px;

          .icon-column {

            app-icon::ng-deep {

              .open-modal-button {
                @include open-modal-button;
              }

            }

          }

        }

        /* TOOLTIP */
        app-icon.mat-tooltip-trigger {
          @include form-field-tooltip;
        }

        /* DATES */
        .dates-container {
          width: 100%;
          display: flex;
          position: relative;
          padding-bottom: 60px;

          label {
            width: 25%;
            height: 40px;
            display: flex;
            align-items: center;
            margin-right: 20px;
            @include font-style(12px, var(--font-regular), var(--gray-color-11), inherit, 150%);
          }

          .dates-fields-container {
            width: calc(75% - 20px);
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            .dates-field-container {
              width: 100%;
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              margin-bottom: 10px;

              .single-date-field {
                width: auto;
                margin-right: 20px;
                display: flex;
                align-items: center;

                label {
                  width: auto;
                  margin-right: 20px;
                  @include font-style(12px, var(--font-regular), var(--gray-color-11), inherit, 150%);
                }

                input {
                  width: -webkit-fill-available;
                  min-width: 160px;
                  height: 40px;
                  position: relative;
                  padding: 10px 15px;
                  box-sizing: border-box;
                  border: none;
                  border-radius: 10px;
                  background: var(--gray-color-12);
                  @include font-style(14px, var(--font-regular), var(--brand-color-09), inherit, 150%);
                  cursor: inherit;

                  &::-webkit-calendar-picker-indicator {
                    width: 18px;
                    height: 100%;
                    position: absolute;
                    right: 10px;
                    padding: 0;
                    background: var(--transparent);
                    cursor: pointer;
                  }

                  &::before {
                    content: '';
                    width: 30px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    background: var(--gray-color-12);
                    pointer-events: none;
                    cursor: pointer;
                  }

                  &::after {
                    content: '|';
                    font-family: var(--datalean-icon-font);
                    font-size: 18px;
                    color: var(--brand-color-10);
                    position: absolute;
                    right: 10px;
                    pointer-events: none;
                    cursor: pointer;
                  }

                }

              }

              button.remove-date {
                width: 20px;
                height: 20px;
                margin: 0;
                padding: 0;
                border: none;
                background: var(--transparent);
                position: relative;
                @include flex-center;
                cursor: pointer;
                transition: var(--standard-transition);

                @media (hover: hover) and (pointer: fine) {

                  &:hover {
                    opacity: 0.5;
                  }

                }

                &::before {
                  content: 'l';
                  font-family: var(--datalean-icon-font);
                  font-size: 16px;
                  color: var(--red-color);
                  position: absolute;
                  cursor: pointer;
                  transition: var(--standard-transition);
                }

              }

            }

          }

          button.add-date {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: auto;
            height: 40px;
            margin-left: auto;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            border: 3px solid var(--brand-color-02);
            border-radius: 10px;
            background: var(--white-color);
            cursor: pointer;
            transition: var(--standard-transition);

            @media (hover: hover) and (pointer: fine) {

              &:hover {
                background: var(--brand-color-02);

                app-icon {
                  color: var(--white-color);
                }

                span {
                  color: var(--white-color);
                }

              }

            }

            app-icon {
              color: var(--brand-color-02);
              margin-right: 10px;
              cursor: pointer;
              transition: var(--standard-transition);
            }

            span {
              @include font-style(14px, var(--font-semibold), var(--brand-color-02), 1.4px, 100%);
              cursor: pointer;
              text-transform: uppercase;
              transition: var(--standard-transition);
            }

          }

        }

      }

      label {
        //Es. testo "Hours"
        height: 40px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        @include font-style(12px, var(--font-regular), var(--gray-color-11), inherit, 150%);
      }

    }

  }

}
